
import React, { useState, useEffect, useContext, useRef } from 'react'
import Api from '../common/Api'
import { context } from '../context'
import { Button, Input, Table, Form, Switch, Modal, Select, Statistic, Row, Col, Card, Dropdown, Drawer, Space } from 'antd'
import { confirmSWAL, getPropertyUrl, getSearchUrl, makeAutoComplete, swalAlert } from '../common/utils'
import { EMPLOYEE_ROLES, PROPERTY_TYPES } from '../common/enums'
import { colorsArray } from '../common/colors'
import _ from "lodash"
import { SearchOutlined } from '@ant-design/icons'
import config from '../config'
import MDEditor, { commands } from '@uiw/react-md-editor'
const { TextArea } = Input

export default function Categoriesseo() {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalData, setModalData] = useState(null)
    const [area, setarea] = useState(null)
    const inputref = useRef(null)
    const [data, setData] = useState([])
    const [selectedProperty, setSelectedProperty] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const searchInput = useRef(null)
    const [searchedColumn, setSearchedColumn] = useState('')

    async function getMetaTags() {
        let result = await Api.getCategorySeo()
        if (result?.status) {
            setData(result?.data)
        }
    }
    useEffect(() => {
        getMetaTags()
    }, [])

    const handleCancel = () => {
        setModalData(null)
        setIsModalVisible(false)
        if (inputref?.current) {
            inputref.current.value = ''
        }
    }
    useEffect(() => {
        makeAutoComplete("area-autocomplete", a => setarea(a))
    }, [isModalVisible])

    const onOk = async () => {
        if (!modalData?.category || !modalData?.type) {
            return swalAlert("please select all feilds", false)
        }

        const r = await Api.categorySeo(modalData)
        swalAlert(r?.message, r?.status)
        if (r?.status) {
            getMetaTags()
            handleCancel()
        }
    }
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchedColumn(dataIndex)
    }

    const rendetText = (text) => {
        if (text?.toLowerCase() == "buy") {
            return "sale"
        }
        else if (text?.toLowerCase() == "agriland") {
            return "agriculture-land"
        }
        return text
    }
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && clearFilters()}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString()?.toLowerCase()?.includes(value?.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <div className=''>{text ? text?.toString() : ''}</div>
            ) : (
                rendetText(text)
            ),
    })
    const columns = [
        {
            title: 'Category',
            dataIndex: 'category',
            ...getColumnSearchProps("category")

        }, {
            title: 'Type',
            dataIndex: 'type',
            ...getColumnSearchProps("type")
        },
        {
            title: "Delete Seo",
            render: (res) => <Button type="danger" className="" onClick={async (e) => {
                e?.stopPropagation()
                if (await confirmSWAL(`Are you sure, do you want to delete ?`)) {
                    let result = await Api.deleteCategorySeo({ id: res?._id })
                    if (result?.status) {
                        getMetaTags()
                        setModalData(null)
                    }
                    swalAlert(result?.message || "something went wrong", true)
                }
            }}>Delete</Button>
        }
    ]

    const updateTag = async () => {
        let payload = {
            ...modalData,
            seo: modalData?.seo ? JSON.parse(modalData?.seo) : {}
        }
        const r = await Api.updateCategorySeo(payload)
        swalAlert(r?.message, r?.status)
        if (r?.status) {
            getMetaTags()
            handleCancel()

        }
    }
    const uploadImage = async (e, blogImage) => {
        const formData = new FormData()

        formData.append('file', e.target.files[0])
        formData.append('folder', 'blogAssets')
        // setLoader(true)
        const response = await Api.uploadFile(formData)
        // setLoader(false)
        if (blogImage) {
            return response?.data
        }
        if (!response?.status) {
            swalAlert(response?.message, false)
        }
    }
    const handleImageUpload = async (files) => {

        if (files) {
            let file = files.target.files[0];
            const reader = new FileReader()
            reader.onload = (e) => {
                const img = new Image();
                img.onload = async () => {
                    const width = img.width;
                    const height = img.height;
                    const imageUrl = await uploadImage(files, true)
                    insertImage(imageUrl, width, height)
                };
                img.src = e.target.result;
            }
            reader.readAsDataURL(file);
        }

    }


    const insertImage = (imageUrl, width, height) => {
        let markdown = modalData?.content
        const newMarkdown = `${markdown} <img style="height:${height}px;width:${width}px" src=${imageUrl} />`;
        setModalData({ ...modalData, content: newMarkdown })
    };
    return (
        <div className='p-1'>
            <h2>Categories seo</h2>
            <Button type='primary' onClick={() => {
                setIsModalVisible(true)
                setModalData(null)
            }} className="my-2">Add Seo</Button>
            <Modal title={'Add Seo'}
                open={isModalVisible} onOk={onOk} onCancel={handleCancel}>
                <Form.Item label="Type">
                    <Select
                        placeholder="Enter type"
                        value={modalData?.type}
                        onChange={e => { setModalData({ ...modalData, type: e }) }}
                        options={
                            [{ key: "sale", value: "buy" }, { key: "rent", value: "rent" }].map((val) => ({
                                value: val?.value,
                                label: val?.key
                            }))
                        } />
                </Form.Item>
                <Form.Item label="category">
                    <Select
                        placeholder="Enter category"
                        value={modalData?.category}
                        onChange={e => {
                            setModalData({ ...modalData, category: e })
                        }}
                        options={
                            PROPERTY_TYPES.filter(val => val?.actions.includes(modalData?.type?.toUpperCase())).map((val) => ({
                                value: val.key,
                                label: val.title
                            }))
                        } />
                    <p style={{ color: 'red' }}>(* please select type to get categories list)</p>
                </Form.Item>

                {/* <div className='d-flex align-items-center '>
                    <p className='m-0 pe-4'>area:</p>
                    <input type='text' ref={inputref} id="area-autocomplete" style={{ width: '100%', height: 35, borderColor: 'lightgrey' }} />
                </div> */}
            </Modal >
            <Table onRow={val => ({
                onClick: () => {
                    let res = _.omit((val || {}), ["createdAt", "updatedAt"])
                    res = {
                        ...res,
                        seo: JSON.stringify(res?.seo, null, 2)
                    }
                    setModalData(res)
                }
            })} columns={columns} dataSource={data} rowKey='id' />
            <Drawer open={modalData && !isModalVisible} onClose={() => setModalData(null)}
                title={rendetText(modalData?.category) + " for " + rendetText(modalData?.type) + " (Seo Tags Details)"} width={720}>
                <h5 className=''>Seo</h5>
                <TextArea
                    style={{ width: "100%" }}
                    rows={8}
                    placeholder="enter seo json here"
                    value={modalData?.seo}
                    onChange={e => setModalData({ ...modalData, seo: e.target.value })} />
                <br />
                <h5 className='mt-3'>Content</h5>
                <div className='my-3'>
                    <MDEditor
                        height={"40vh"}
                        showPreview={false}
                        value={modalData?.content}
                        onChange={(e) => setModalData({ ...modalData, content: e })}
                        commands={[
                            ...commands.getCommands().map((r) => {
                                if (r["keyCommand"] == "image") {
                                    return
                                }
                                if (r["keyCommand"] === "group") {
                                    return r["children"].map(cr => r);
                                }
                                return r;
                            }).flat().map(r => r),

                            {
                                name: 'upload-image',
                                keyCommand: 'upload-image',
                                buttonProps: { 'aria-label': 'Upload Image' },
                                icon: <img src="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png" style={{ width: '15px', height: '15px' }} />,
                                execute: () => { document.getElementById('imageFileInput').click(); }
                            }
                        ]}
                    />
                    <input
                        id="imageFileInput"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => { if (e) { handleImageUpload(e) } }}
                    />
                </div>
                <h5>Remarks</h5>
                <TextArea
                    style={{ width: "100%" }}
                    rows={3}
                    placeholder="enter remarks here"
                    value={modalData?.remarks}
                    onChange={e => setModalData({ ...modalData, remarks: e.target.value })} />
                <Button type="primary" className="my-3 me-3" onClick={updateTag}>Save</Button>
            </Drawer>
        </div >
    )


}