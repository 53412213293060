import React, { useState, useEffect, useContext, useRef } from 'react'
import Api from '../common/Api'
import { context } from '../context'
import {
    Table, Switch, Drawer, Descriptions, Button, Row, Col,
    Card, Statistic, Segmented, Select, Input, Space, Dropdown
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { confirmSWAL, getPropertyUrl, swalAlert } from '../common/utils'
import config from '../config'
import { colorsArray } from '../common/colors'
import { PROPERTY_TYPES } from '../common/enums'
import { useSearchParams } from 'react-router-dom'


const renderBoolKeys = [
    { key: 'isActive' },
    { key: 'isFeatured', rankingKey: 'featuredRanking' },
    { key: 'isSpotlight', rankingKey: "spotlightRanking" },
    { key: 'isTop', rankingKey: "topRanking" },
    { key: 'isApproved' },
    { key: 'isMostVisited', rankingKey: "mostVisitedRanking" },
    { key: 'isCommercialTop', rankingKey: "commercialTopRanking" }
]
const { TextArea } = Input

export default function Properties() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [categories, setCategories] = useState([])
    const [properties, setProperties] = useState([])
    const [filter, setFilter] = useState({})
    const [boolFilter, setBoolFilter] = useState(null)
    const [analytics, setAnalytics] = useState({})
    const { setLoader } = useContext(context)
    const [selectedProperty, setSelectedProperty] = useState(null)
    const [actions, setActions] = useState(searchParams.get('actions') || 'all')
    const [remarks, setRemarks] = useState({})

    function sorting(key, data) {
        key = key.replace('is', '')
        key = key.charAt(0).toLowerCase() + key.substring(1);
        data.sort((a, b) => {
            if (key in a && key in b) {
                return a[key] - b[key];
            } else if (key in a) {
                return -1
            } else if (key in b) {
                return 1
            }
            return 0
        })
        return data
    }


    const fetchAllProperties = async (query) => {
        setLoader(true)
        let payload = {
            ...query,
            populate: {
                "seller": "name role mobile leads seo",
            },
            page: 1,
            limit: 9999
        }
        if (payload?.isApproved == "all") {
            delete payload.isApproved
        }
        if (actions !== 'all') {
            payload.actions = actions
        }

        const response = await Api.searchProperties(payload)
        setLoader(false)
        if (response?.status) {
            let re = response.data?.map(r => ({
                ...r,
                sellerName: r.seller?.name,
                sellerRole: r.seller?.role,
                sellerMobile: r.seller?.mobile,
                leadsCount: r?.leads?.length
            }))
            let objLength = Object.keys(filter)
            if (objLength.length > 1) {
                re = sorting(objLength[1] + 'Ranking', re)
            }
            setProperties(re)
        } else {
            swalAlert(response?.message, false)
        }
    }

    useEffect(() => {
        fetchAllProperties(filter)
    }, [filter, actions])

    useEffect(() => {
        let anal = {
            All: properties.length,
        }
        Object.keys(categories).map(category => {
            anal[category] = properties.filter(r => r.type === category).length
        })
        setAnalytics(anal)
    }, [properties])

    useEffect(() => {

        let actionsType = actions || 'all'
        if (actionsType === 'all') {
            setCategories(PROPERTY_TYPES.reduce((obj, item) => Object.assign(obj, { [item.key]: item.title }), {}))
        } else {
            let categoriesData = PROPERTY_TYPES.filter(r => r.actions.includes(actionsType))
            setCategories(categoriesData.reduce((obj, item) => Object.assign(obj, { [item.key]: item.title }), {}))
        }
        setSearchParams({ ...searchParams, actions: actionsType, isApproved: filter.isApproved || 'all' })

    }, [actions])

    const updateProperty = async (id, payload) => {
        setLoader(true)
        const data = await Api.editProperty({
            id,
            ...payload
        })
        setLoader(false)
        if (data?.status) {
            swalAlert('Property updated sucessfully!!')
            setProperties(properties.map(ad => {
                if (ad._id === id) {
                    return {
                        ...ad,
                        ...payload
                    }
                }
                return ad
            }))
            if (selectedProperty) {
                setSelectedProperty({ ...selectedProperty, ...payload })
            }
        } else {
            swalAlert(data?.message, false)
        }
    }

    const [searchedColumn, setSearchedColumn] = useState('')
    const searchInput = useRef(null)
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchedColumn(dataIndex)
    }
    const renderText = (text) => {
        text = String(text)
        if (text?.toLowerCase() == "buy") {
            return "SALE"
        }
        else if (text?.toLowerCase() == "agriland") {
            return "Agriculture-land"
        }
        return text
    }
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && clearFilters()}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString()?.toLowerCase()?.includes(value?.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <div className=''>{text ? text?.toString() : ''}</div>
            ) : (
                text
            ),
    })

    const columns = [
        {
            title: "Id",
            dataIndex: "pId",
            key: 'pId',
            ...getColumnSearchProps('pId')
        },
        {
            title: "Seller",
            dataIndex: "sellerName",
            ...getColumnSearchProps('sellerName')
        },
        {
            title: "Mobile",
            dataIndex: "sellerMobile",
            ...getColumnSearchProps('sellerMobile')
        },
        {
            title: "title",
            dataIndex: "title",
            ...getColumnSearchProps('title'),
        },
        {
            title: 'type',
            dataIndex: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type),
            render: (t, r) => renderText(r.type)

        },
        {
            title: 'Leads',
            dataIndex: 'leadsCount',
            render: (a, b) => a || 0,
        },
        {
            title: "isActive",
            dataIndex: "isActive",
            render: (t, r) => <Switch checked={r.isActive} onChange={(checked, e) => {
                e.stopPropagation()
                updateProperty(r._id, { isActive: checked })
            }} />
        }, {
            title: "Sale/Rent",
            dataIndex: "actions",
            render: (t, r) => renderText(r.actions?.join(', '))
        }, {
            title: "db id",
            dataIndex: "_id",
            key: '_id',
        },
    ]
    const renderProperty = () => {
        return <>
            <Descriptions title={selectedProperty.type} bordered>
                <Descriptions.Item label="Id" span={3}>{selectedProperty.pId}</Descriptions.Item>
                <Descriptions.Item label="Title" span={3}>{selectedProperty.title}</Descriptions.Item>
                <Descriptions.Item label="Seller" span={3}>{selectedProperty?.sellerName}</Descriptions.Item>
                <Descriptions.Item label="Seller Mobile" span={3}>{selectedProperty?.sellerMobile}</Descriptions.Item>
                <Descriptions.Item label="Seller Role" span={3}>{selectedProperty?.sellerRole}</Descriptions.Item>
                {renderBoolKeys.map((value, i) => <Descriptions.Item label={value?.key.replace('is', '')} span={3} key={i}>
                    <div className='d-flex align-items-center justify-content-between'>
                        <Switch checked={selectedProperty[value?.key]} onChange={(checked) => {
                            if (!['isApproved', 'isActive'].includes(value?.key)
                                && checked) {
                                if (!selectedProperty.isApproved) {
                                    return swalAlert('please approve this property before editing', false)
                                } else if (!selectedProperty.isActive) {
                                    return swalAlert('please activate this property before editing', false)
                                }
                            }
                            updateProperty(selectedProperty._id, {
                                [value?.key]: checked
                            })
                        }} />
                        {(!['isApproved', 'isActive'].includes(value?.key)) ?
                            <div className='d-flex text-nowrap ms-2 align-items-center justify-content-end'>
                                Rank:
                                <Input onChange={(e) => {
                                    e = e?.target.value
                                    if (e < 0) {
                                        return
                                    }
                                    setSelectedProperty({ ...selectedProperty, [value?.rankingKey]: Number(e) })
                                }} className='mx-2 w-25' type='number' value={selectedProperty[value.rankingKey] || 0} />
                                <Button type="primary" onClick={async () => {
                                    const res = {
                                        id: selectedProperty._id,
                                        [value.rankingKey]: selectedProperty[value.rankingKey] || 0
                                    }
                                    setLoader(true)
                                    const data = await Api.editProperty(res)
                                    setLoader(false)
                                    if (!data?.status) {
                                        return swalAlert(data?.message, false)
                                    }
                                    properties.forEach(p => {
                                        if (p._id === selectedProperty._id) {
                                            p[value.rankingKey] = selectedProperty[value.rankingKey]
                                        }
                                    })
                                    setProperties([...sorting(value.rankingKey, properties)])
                                    swalAlert("rank updated successfully")
                                }} className="my-2">{'Update'}</Button>
                            </div>
                            : null}</div>
                </Descriptions.Item>)}
                <Descriptions.Item label="View" span={3}>
                    <Button type="primary" onClick={() => window.open(`${config.MAIN_WEB_URL}/${getPropertyUrl(selectedProperty)}`, '_blank').focus()}>View Property</Button>
                </Descriptions.Item>
                <Descriptions.Item label="Edit" span={3}>
                    <Button type="primary" danger ghost onClick={() => window.open(`${config.MAIN_WEB_URL}/property/edit/${selectedProperty._id}`, '_blank').focus()}>Edit Property</Button>
                </Descriptions.Item>
                <Descriptions.Item label="Delete" span={3}>
                    <Button type="primary" danger onClick={async () => {
                        if (await confirmSWAL('Are you sure you want to delete this property?')) {
                            setLoader(true)
                            const data = await Api.deleteProperty(selectedProperty._id)
                            setLoader(false)
                            if (data?.status) {
                                swalAlert('Property deleted sucessfully!!')
                                setProperties(properties.filter(p => p._id !== selectedProperty._id))
                                setSelectedProperty(null)
                            } else {
                                swalAlert(data?.message, false)
                            }
                        }
                    }}>Delete Property</Button>
                </Descriptions.Item>
                <Descriptions.Item label="Db Id" span={3}>{selectedProperty._id}</Descriptions.Item>

            </Descriptions>
            <div className="text-danger">
                * Note: Property must be both Active and Approved to show on website/home page
            </div>
            <br />
            <div>
                <div className=' align-items-center'>
                    <p className='text-nowrap fw-bold'>Custom URL:</p>
                    <Input value={selectedProperty?.url} onChange={(e) => setSelectedProperty({ ...selectedProperty, url: e.target.value })}
                        className='py-2 ' placeholder="Custom URL" />

                </div>
                <Button type="primary" onClick={async () => {
                    const res = {
                        id: selectedProperty._id,
                        url: selectedProperty?.url
                    }
                    setLoader(true)
                    const data = await Api.editProperty(res)
                    setLoader(false)
                    if (!data?.status) {
                        return swalAlert(data?.message, false)
                    }
                    swalAlert("URL updated successfully")
                }} className="my-2">{selectedProperty?.url ? 'Update' : 'save'}</Button>
            </div>
            <br />
            <h2>Lead</h2>
            <Space direction="horizantal" className="my-2">
                <label>Name: </label>
                <Input
                    style={{ width: '100%' }}
                    placeholder="Enter name"
                    value={selectedProperty.leadName}
                    onChange={(e) => setSelectedProperty({ ...selectedProperty, leadName: e.target.value })} />
            </Space>
            <br />
            <Space direction="horizantal" className="my-2">
                <label>Mobile: </label>
                <Input
                    style={{ width: '100%' }}
                    placeholder="Enter mobile"
                    value={selectedProperty.leadMobile}
                    onChange={(e) => setSelectedProperty({ ...selectedProperty, leadMobile: e.target.value })} />
            </Space>
            <br />
            <Space direction="horizantal" className="my-2">
                <label>Email: </label>
                <Input
                    style={{ width: '100%' }}
                    placeholder="Enter Email"
                    value={selectedProperty.leadEmail}
                    onChange={(e) => setSelectedProperty({ ...selectedProperty, leadEmail: e.target.value })} />
            </Space>
            <br />
            <Button type="primary" onClick={async () => {
                const lead = {
                    propertyId: selectedProperty._id,
                    name: selectedProperty.leadName,
                    mobile: selectedProperty.leadMobile,
                    email: selectedProperty.leadEmail,
                }
                setLoader(true)
                const data = await Api.addLead(lead)
                setLoader(false)
                if (!data?.status) {
                    return swalAlert(data?.message, false)
                }
                setSelectedProperty({ ...selectedProperty, leadName: '', leadMobile: '', leadEmail: '' })
                swalAlert('Lead added sucessfully')
            }} className="my-2">Save</Button>
            <br />
            <h2>Seo</h2>
            <TextArea
                style={{ width: "100%" }}
                rows={10}
                placeholder="enter seo json here"
                value={selectedProperty?.seo || ''}
                onChange={e => setSelectedProperty({ ...selectedProperty, seo: e.target.value })} />
            <br />

            <Button type="primary" onClick={async () => {
                const res = {
                    id: selectedProperty._id,
                    seo: JSON.parse(selectedProperty?.seo)
                }
                setLoader(true)
                const data = await Api.editProperty(res)
                setLoader(false)
                if (!data?.status) {
                    return swalAlert(data?.message, false)
                }
                let fil = properties?.map(r => {
                    if (r?._id == selectedProperty?._id) {
                        return {
                            ...r,
                            seo: JSON.parse(selectedProperty?.seo)
                        }
                    }
                    return r
                })
                setProperties(fil)
                swalAlert('property updated sucessfully')
            }} className="my-2">Save</Button>
            <h5 className="my-3">Remarks </h5>
            <TextArea
                style={{ width: "100%" }}
                rows={3}
                placeholder="Enter remarks here"
                value={selectedProperty?.remarks}
                onChange={e => setSelectedProperty({ ...selectedProperty, remarks: e.target.value })} />
            <br />
            <Button type="primary" onClick={async () => {
                const res = {
                    id: selectedProperty._id,
                    remarks: selectedProperty?.remarks
                }
                setLoader(true)
                const data = await Api.editProperty(res)
                setLoader(false)
                if (!data?.status) {
                    return swalAlert(data?.message, false)
                }
                let fil = properties?.map(r => {
                    if (r?._id == selectedProperty?._id) {
                        return {
                            ...r,
                            remarks: selectedProperty?.remarks
                        }
                    }
                    return r
                })
                setProperties(fil)
                swalAlert('remarks updated sucessfully')
            }} className="my-2">Save Remarks</Button>
        </>
    }
    return <>
        <h2>Properties</h2>
        <br />
        <Row gutter={16}>
            {Object.keys(analytics).map((anal, i) => <Col span={4} key={i}>
                <Card bordered={false} style={{ backgroundColor: colorsArray[i] }} onClick={() => {
                    if (anal === 'All') {
                        setFilter({})
                    } else {
                        setFilter({ type: anal })
                    }
                    setBoolFilter(null)
                }} className="my-2 propCard">
                    <Statistic
                        title={categories[anal] || anal}
                        value={analytics[anal]}
                        valueStyle={{ color: 'white' }}
                    />
                </Card>
            </Col>)}
        </Row>
        <Segmented options={['All', ...renderBoolKeys.map(k => {
            if (k.key == 'isApproved') return
            return k.key.replace('is', '')
        })]} onChange={v => {
            setFilter(v === 'All' ? {} : { isApproved: filter?.isApproved, [`is${v}`]: true })
            setBoolFilter(v)
        }} className='my-2 mr-4' value={boolFilter || 'All'} />
        <Select options={[
            { label: 'Sale/Rent', value: 'all' },
            { label: 'Sale', value: 'BUY' },
            { label: 'Rent', value: 'RENT' },
        ]} onChange={v => {
            setActions(v)
        }} className='' value={actions || 'all'} style={{ width: 150 }} />
        <Select options={[
            { label: 'All', value: 'all' },
            { label: 'Approved', value: true },
            { label: 'Not Approved', value: false },
        ]} onChange={v => {
            if (v == 'all') {
                let res = { ...filter }
                delete res.isApproved
                setFilter(res)
                setSearchParams({ ...searchParams, actions: actions, isApproved: 'all' })
                return
            }
            setFilter({ ...filter, isApproved: v })
            setSearchParams({ ...searchParams, actions: actions, isApproved: v })

        }} className='' value={(!Object.keys(filter).includes("isApproved") || filter?.isApproved == "all" || filter?.isApproved == undefined) ? "all" : filter?.isApproved ? "Approved" : "Not Approved"} style={{ width: 150 }} />

        <br />
        <Button type='primary' onClick={() => {
            window.open(`${config.MAIN_WEB_URL}/property/post`, '_blank').focus()
        }} className="my-2">Add Property</Button>
        <br />
        <Table
            onRow={val => ({ onClick: () => { setSelectedProperty({ ...val, seo: JSON.stringify(val?.seo, null, 2) || '' }) } })}
            columns={columns}
            dataSource={properties}
            rowKey='id'
        />
        <Drawer open={selectedProperty ? true : false} onClose={() => setSelectedProperty(null)}
            title="Property Details" width={720}>
            {selectedProperty && renderProperty()}
            {/* <pre>{JSON.stringify(selectedProperty, null, 4)}</pre> */}
        </Drawer>
    </>
}