const colors = {
    't1': '#7dba00',
    't2': '#f2b544',
    't3': "#2aa4bf",
    't4': '#7578bf',
    't5': '#ff5a87',
    't6': '#6c838c',

    "red": "#ef5350",
    "pink": "#e91e63",
    "purple": "#ab47bc",
    "deepPurple": "#673ab7",
    "indigo": "#3f51b5",
    "blue": "#42a5f5",
    "lightBlue": "#039be5",
    "cyan": "#00acc1",
    "teal": "#009688",
    "green": "#4caf50",
    "lightGreen": "#7cb342",
    "lime": "#c0ca33",
    "yellow": "#ffd600",
    "amber": "#ffb300",
    "orange": "#fb8c00",
    "deepOrange": "#f4511e",
    "brown": "#795548",
    "grey": "#757575",
    "blueGrey": "#607d8b",


    //duplicate
    't11': '#7dba00',
    't21': '#f2b544',
    't31': "#2aa4bf",
    't41': '#7578bf',
    't51': '#ff5a87',
    't61': '#6c838c',
    "red1": "#ef5350",
    "pink1": "#e91e63",
    "1purple": "#ab47bc",
    "1deepPurple": "#673ab7",
    "1indigo": "#3f51b5",
    "b1lue": "#42a5f5",
    "li1ghtBlue": "#039be5",
    "cya1n": "#00acc1",
    "1teal": "#009688",
    "g1reen": "#4caf50",
    "li1ghtGreen": "#7cb342",
    "lim1e": "#c0ca33",
    "yell1ow": "#ffd600",
    "amber1": "#ffb300",
    "orange1": "#fb8c00",
    "deepOra1nge": "#f4511e",
    "brown1": "#795548",
    "1grey": "#757575",
    "b1lueGrey": "#607d8b"
}
//https://www.materialpalette.com/colors
export default colors

const colorsArray = Object.keys(colors).map(key => colors[key])
export { colorsArray }