import React, { useState, useEffect, useContext, useRef } from 'react'
import {
    Table, Drawer, Descriptions, Button, Switch, Avatar, Row,
    Col, Card, Statistic, Input, Space, Form, Modal, Select
} from 'antd'
import Api from "../common/Api"
import { context } from '../context'
import { confirmSWAL, swalAlert } from '../common/utils'
import { USER_ROLES } from '../common/enums'
import config from '../config'
import { UserOutlined } from '@ant-design/icons'
import { colorsArray } from '../common/colors'
import { SearchOutlined } from '@ant-design/icons'

export default function Users() {
    const [users, setUsers] = useState([])
    const [currentUser, setCurrentUser] = useState(null)
    const [filter, setFilter] = useState({})
    const { setLoader } = useContext(context)
    const [analytics, setAnalytics] = useState({})
    const [modalData, setModalData] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false)

    const getUsers = async (query = {}) => {
        setLoader(true)
        let response = await Api.searchUsers(query)
        setLoader(false)
        if (response?.status) {
            setUsers(response.data.map(val => ({ ...val, id: val._id })) || [])
            let anal = {
                All: response.data.length,
            }
            Object.keys(USER_ROLES).forEach(r => {
                anal[r] = response.data.filter(ad => ad.role === r).length
            })
            setAnalytics(anal)
        } else {
            swalAlert(response?.message, false)
        }
    }

    const updateUser = async (id, data) => {
        setLoader(true)
        let response = await Api.updateUser({ id, ...data })
        setLoader(false)
        if (response?.status) {
            swalAlert('User updated sucessfully!!')
            setUsers(users?.map(val => {
                if (val.id === id) {
                    return {
                        ...val,
                        ...data
                    }
                }
                return val
            }))
            if (currentUser) {
                setCurrentUser({ ...currentUser, ...data })
            }
        } else {
            swalAlert(response?.message, false)
        }
    }

    useEffect(() => {
        getUsers(filter)
    }, [filter])


    const renderSeller = () => {
        const isBuilder = currentUser?.role === USER_ROLES.BUILDER
        const url = `${config.MAIN_WEB_URL}/${isBuilder ? 'seller' : 'agent'}/${currentUser.id}`
        return <>
            <Descriptions.Item label="Profile" span={3}>
                <Button type="primary" onClick={() => window.open(url, '_blank').focus()}>
                    View Profile
                </Button>
            </Descriptions.Item>
            <Descriptions.Item label="Featured" span={3}>
                <Switch checked={currentUser.isFeatured} onChange={(checked) => {
                    updateUser(currentUser.id, {
                        isFeatured: checked
                    })
                }} />
            </Descriptions.Item>
            <Descriptions.Item label="Verified" span={3}>
                <Switch checked={currentUser.isVerified} onChange={(checked) => {
                    updateUser(currentUser.id, {
                        isVerified: checked
                    })
                }} />
            </Descriptions.Item>
            {/* <Descriptions.Item label="Gallery" span={3}>
                <div style={{ overflow: 'auto', maxWidth: '90%', maxHeight: 160 }}>
                    {[...currentUser?.images, ...currentUser?.images, ...currentUser?.images, ...currentUser?.images]?.map((val, index) => <img src={val} alt="no profile pic" height={150} width={150} className="m-2" />)}
                </div>
            </Descriptions.Item> */}
        </>
    }

    const renderBuyer = () => {
        return <>

        </>
    }

    const [searchedColumn, setSearchedColumn] = useState('')
    const searchInput = useRef(null)
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchedColumn(dataIndex)
    }
    const getColumnSearchProps = (dataIndex) => ({
        //eslint-disable-next-line no-unused-vars
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString()?.toLowerCase()?.includes(value?.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <div className=''>{text ? text?.toString() : ''}</div>
            ) : (
                text
            ),
    })

    const renderUser = () => {
        return <>
            {currentUser.profileImage ? <img src={currentUser.profileImage} alt="no profile pic" className='mx-auto d-block'
                style={{ width: 100, height: 100, borderRadius: 50 }} />
                : <Avatar size={100} icon={<UserOutlined />} className='mx-auto d-block' />}
            <Descriptions title={currentUser?.role} bordered>
                <Descriptions.Item label="Id" span={3}>{currentUser.id}</Descriptions.Item>
                <Descriptions.Item label="Name" span={3}>{currentUser.name}</Descriptions.Item>
                <Descriptions.Item label="Mobile" span={3}>{currentUser.mobile}</Descriptions.Item>
                <Descriptions.Item label="Email" span={3}>{currentUser.email || '-'}</Descriptions.Item>
                <Descriptions.Item label="Otp" span={3}>
                    <Button type="primary" onClick={async () => {
                        setLoader(true)
                        let response = await Api.generateUserLoginOtp({ userId: currentUser.id })
                        setLoader(false)
                        if (!response?.status) {
                            swalAlert(response?.message, false)
                        }
                        await confirmSWAL(response?.message)
                    }}>Get Otp</Button>
                </Descriptions.Item>
                <Descriptions.Item label="Delete" span={3}>
                    <Button type="primary" danger onClick={async () => {
                        if (await confirmSWAL('Are you sure you want to delete this user?')) {
                            setLoader(true)
                            let response = await Api.deleteUser(currentUser.id)
                            setLoader(false)
                            if (response?.status) {
                                swalAlert('User deleted sucessfully!!')
                                setUsers(users.filter(val => val.id !== currentUser.id))
                                setCurrentUser(null)
                            } else {
                                swalAlert(response?.message, false)
                            }
                        }
                    }}>Delete User</Button>
                </Descriptions.Item>
                {currentUser?.role === USER_ROLES.BUYER ? renderBuyer() : renderSeller()}
            </Descriptions>
            {/* <pre>
                {JSON.stringify(currentUser, null, 4)}
            </pre> */}
        </>
    }

    const addUser = async () => {
        let err
        if (!modalData.name) {
            err = 'Please enter name'
        } else if (!modalData.mobile) {
            err = 'Please enter mobile'
        } else if (!modalData.role) {
            err = 'Please select role'
        } else if (isNaN(parseInt(modalData.mobile)) || modalData.mobile.length !== 10) {
            err = 'Please enter valid mobile'
        }
        if (err) {
            return swalAlert(err, false)
        }

        const data = await Api.createUser({
            ...modalData,
            mobile: `91-${modalData.mobile}`
        })
        if (data?.status) {
            swalAlert('User added sucessfully!!')
            setModalData({})
            getUsers()
            setIsModalVisible(false)
        } else {
            swalAlert(data?.message, false)
        }
    }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps('name'),
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            sorter: (a, b) => a.role > b.role,
        },
        {
            title: "Mobile",
            key: "mobile",
            dataIndex: "mobile",
            ...getColumnSearchProps('mobile'),
        },
        {
            title: "Id",
            dataIndex: "id",
            key: "id"
        }
    ]
    return (
        <>
            <h2>Users</h2>
            <br />
            <Row gutter={16}>
                {Object.keys(analytics).map((anal, i) => <Col span={6} key={i}>
                    <Card bordered={false} style={{ backgroundColor: colorsArray[i] }} onClick={() => {
                        setFilter(anal === 'All' ? {} : { role: anal })
                    }} className="my-2">
                        <Statistic
                            title={anal}
                            value={analytics[anal]}
                            valueStyle={{ color: 'white' }}
                        />
                    </Card>
                </Col>)}
            </Row>
            <Button type='primary' onClick={() => {
                setIsModalVisible(true)
                setModalData({
                    role: USER_ROLES.BUYER
                })
            }} className="my-2">Add User</Button>
            <Table onRow={val => ({
                onClick: () => setCurrentUser(val)
            })} columns={columns} dataSource={users} key='id' />
            <Drawer open={currentUser ? true : false} onClose={() => setCurrentUser(null)} title="User Details" width={720}>
                {currentUser && renderUser()}
            </Drawer>
            <Modal title={'Add User'}
                open={isModalVisible} onOk={addUser} onCancel={() => {
                    setModalData({})
                    setIsModalVisible(false)
                }}>
                <Form.Item label="Name">
                    <Input placeholder='Enter Name' maxLength={50}
                        value={modalData?.name} onChange={e => setModalData({ ...modalData, name: e.target.value })} />
                </Form.Item>
                <Form.Item label="Mobile">
                    <Input placeholder='Enter Mobile Number'
                        value={modalData?.mobile} maxLength={10}
                        onChange={e => setModalData({ ...modalData, mobile: e.target.value })} />
                </Form.Item>
                <Form.Item label="Role">
                    <Select value={modalData?.role} onChange={e => setModalData({ ...modalData, role: e })}
                        options={Object.keys(USER_ROLES).map(r => ({
                            label: r,
                            value: r
                        }))} />
                </Form.Item>
            </Modal>
        </>
    )
}