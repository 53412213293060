import Swal from 'sweetalert2'

export function isLoggedIn() {
    return localStorage.getItem('loggedIn') === 'true'
}

export async function confirmSWAL(title = 'Are you sure?', text = '') {
    const result = await Swal.fire({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
    })
    return result.isConfirmed
}

export async function swalAlert(title, isSuccess = true) {
    return await Swal.fire({
        // position: 'bottom-end',
        icon: isSuccess ? 'success' : 'error',
        title,
        showConfirmButton: false,
        timer: 1000
    })
}

export function getAreaFromPlace(place) {
    const area = place?.address_components?.find((val) => val?.types?.includes('sublocality_level_1') || val?.types?.includes('locality'))?.short_name
    return area || place?.formatted_address
}

export function makeAutoComplete(eleId, callback) {
    try {
        const inputEle = document.getElementById(eleId)
        var center = { lat: 17.351555, lng: 78.488416 }//hyderabad
        const distance = 1 //100kms
        const defaultBounds = {
            north: center.lat + distance,
            south: center.lat - distance,
            east: center.lng + distance,
            west: center.lng - distance,
        }
        let autocomplete = new window.google.maps.places.Autocomplete(inputEle, {
            // types: ['(sublocality_level_1,locality,sublocality)'],
            // fields: ["formatted_address", "geometry", "name"],
            componentRestrictions: { country: "IN" },
            bounds: defaultBounds,
            strictBounds: true,
        })
        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace()
            const area = getAreaFromPlace(place)
            callback(area, place)//area, place object
            inputEle.value = area
        })
    } catch (error) {
        console.log({ error })
    }
}

//url converter for single property
export function getPropertyUrl(data) {
    let urlstr = '/property/'
    if (data?.type) {
        let type = data.type
        if (data.type == "agriland") {
            type = "agricultural-land"
        }
        urlstr += type.toLowerCase().trim().split(" ").join('-') + '-for-'
    }
    if (data?.actions?.length) {
        if (data?.actions[0].toLocaleLowerCase() == "buy") {
            data.actions[0] = "sale"
        }
        urlstr += data?.actions[0].toLocaleLowerCase() + '-in-'
    }
    if (data?.area) {
        const areas = data?.area?.toLocaleLowerCase().split(",").map(i => i.trim().split(' ').join("-"))
        urlstr += areas.join('-')
    } else {
        urlstr += 'hyderabad'
    }
    urlstr += '/' + encodeURIComponent(data.title?.toLocaleLowerCase()?.split(" ").filter(i => i).map(i => i.trim()).join("-")) + '/' + data._id
    return urlstr
}

export function getSearchUrl(data) {
    let urlStr = ''
    if (data?.type) {
        if (data?.type == "agriland") {
            data.type = "agriculture-land"
        }
        urlStr += data.type.toLowerCase().trim().split(" ").join("-") + '-for-'
    }
    if (data?.action) {
        let action = data?.action
        if (data?.action.toLocaleLowerCase() == "buy") {
            action = "sale"
        }
        urlStr += action + '-in-'
    }
    if (data?.area) {
        urlStr += data.area.trim().split(" ").join("-") + '-'
    }
    if (!urlStr.includes("hyderabad")) {
        urlStr += 'hyderabad'
    }
    return urlStr
}