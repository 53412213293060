import React, { useState, useEffect, useContext, useRef } from 'react'
import Api from '../common/Api'
import { context } from '../context'
import { Button, Input, Table, Form, Switch, Modal, Select, Spin, Checkbox, Space } from 'antd'
import { confirmSWAL, swalAlert } from '../common/utils'

function DynamicFilters(props) {
    const types = ['dropdown', 'checkbox', 'textbox']

    const [filters, setFilters] = useState([])
    const [newFilterDD, setNewFilterDD] = useState(types[0])
    useEffect(() => {
        setFilters(props?.filters || [])
    }, [])

    useEffect(() => {
        props?.setFilters(filters)
    }, [filters])

    const renderDropdown = (filterData, index) => {
        return <>
            {filterData?.options?.map((optionValue, i) => {
                return <div className="my-2" key={i}>
                    <span>Option {i + 1}: </span>
                    <Space direction="horizontal">
                        <Input placeholder="Enter Option" value={optionValue} onChange={(e) => {
                            setFilters(filters.map((filterValue, filterIndex) => {
                                if (filterIndex === index) {
                                    return { ...filterValue, options: filterData.options.map((opt, j) => j === i ? e.target.value : opt) }
                                }
                                return filterValue
                            }))
                        }} />
                        <Button onClick={() => {
                            setFilters(filters.map((filter, filterIndex) => {
                                if (filterIndex === index) {
                                    return { ...filter, options: filterData.options.filter((_, j) => j !== i) }
                                }
                                return filter
                            }))
                        }} className="mx-2" danger>Remove Option</Button>
                    </Space>
                </div>
            })}
            <Button onClick={() => {
                setFilters(filters.map((filter, i) => {
                    if (i === index) {
                        return { ...filter, options: [...(filter.options || []), ''] }
                    }
                    return filter
                }))
            }} className="my-1">Add Option</Button>
        </>
    }

    const renderCheckbox = (filterData, index) => {
        return <>
            {(filterData && index) ? '' : ''}
        </>
    }

    const renderText = (filterData, index) => {
        return <>
            {(filterData && index) ? '' : ''}
        </>
    }

    const renderAddFilter = () => {
        return <>
            <br />
            Type:
            <Select
                className='mx-2'
                value={newFilterDD}
                options={types.map(type => ({ label: type, value: type }))} style={{ width: 200 }}
                onChange={value => { setNewFilterDD(value) }}
            />
            <Button onClick={() => {
                if (filters.length >= 10) { return swalAlert('You can add only 10 filters', false) }
                setFilters([...filters, { type: newFilterDD, isRequired: false }])
            }} className="mx-2" type="primary">Add Filter</Button>
        </>
    }

    const renderCommonFields = (filterData, index) => {
        return <>
            <Space direction="horizontal" className='my-1'>
                <span>Name: </span>
                <Input placeholder="Enter Filter Name" value={filterData.name} onChange={(e) => {
                    setFilters(filters.map((filter, i) => {
                        if (i === index) {
                            return { ...filter, name: e.target.value }
                        }
                        return filter
                    }))
                }} />
            </Space>
            <br />
            <Space direction="horizontal" className='my-1'>
                <span>Required: </span>
                <Switch checked={filterData.isRequired === true ? true : false} onChange={(isChecked) => {
                    setFilters(filters.map((filter, i) => {
                        if (i === index) {
                            return { ...filter, isRequired: isChecked ? true : false }
                        }
                        return filter
                    }))
                }} />
            </Space>
            <br />
        </>
    }
    return <>
        {filters.map((filter, index) => {
            return <div key={index} className="p-2 my-2 bg-light border rounded-3">
                <div>{index + 1}{") "} {filter.type}</div>
                {renderCommonFields(filter, index)}
                <div className='my-2'>
                    {filter.type === 'dropdown' && renderDropdown(filter, index)}
                    {filter.type === 'checkbox' && renderCheckbox(filter, index)}
                    {filter.type === 'textbox' && renderText(filter, index)}
                </div>
                <Button type='danger' onClick={() => {
                    setFilters(filters.filter((_, i) => i !== index))
                }}>Delete Filter</Button>
            </div>
        })}
        {renderAddFilter()}
    </>
}

export default function Categories() {
    const { setLoader } = useContext(context)
    const [categories, setCategories] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalData, setModalData] = useState({})
    const [key, setKey] = useState(0)
    const [type, setType] = useState('Add')
    const refInput = useRef([])
    const [localLoader, setLocalLoader] = useState(false)

    const fetchAllCategories = async (query = {}) => {
        setLoader(true)
        const res = await Api.searchCategories(query)
        setLoader(false)
        if (res?.status) {
            setCategories(res.data.map(ad => ({ ...ad, id: ad._id })))
        } else {
            swalAlert(res?.message, false)
        }
    }

    useEffect(() => {
        fetchAllCategories()
    }, [])
    const updateCall = (id, payload) => {
        swalAlert('category updated sucessfully!!')
        setCategories(categories.map(ad => {
            if (ad.id === id) {
                return {
                    ...ad,
                    ...payload
                }
            }
            return ad
        }))
    }
    const updateCategory = async (id, payload) => {
        const data = await Api.updateCategory({
            ...payload,
            id,
        })
        if (data?.status) {
            updateCall(id, payload)
        } else {
            swalAlert(data?.message, false)
        }
    }

    const onDelete = async (id) => {
        if (await confirmSWAL('Are you sure?', "Do you want to delete this Category ?")) {
            const data = await Api.deleteCategory(id)
            if (data?.status) {
                swalAlert('category deleted sucessfully!!')
                fetchAllCategories()
            } else {
                swalAlert(data?.message, false)
            }
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        }, {
            title: 'Icon',
            dataIndex: 'icon',
            render: (t, r) => <img src={r.icon} alt={r.name} style={{ width: 50, height: 50 }} />
        },
        {
            title: "isActive",
            dataIndex: "isActive",
            render: (t, r) => <Switch checked={r.isActive} onChange={(checked, e) => { e?.stopPropagation(); updateCategory(r.id, { isActive: checked }) }} />
        },
        {
            title: "Action",
            render: (t, r) => <Button type='primary' danger onClick={(e) => { e?.stopPropagation(); onDelete(r.id) }}>Delete</Button>
        },
        {
            title: "Id",
            dataIndex: "id",
            key: 'id',
        }
    ]

    const OnModalOk = async () => {
        if (!modalData.name) return swalAlert('Please enter category name', false)
        if (!modalData.icon) return swalAlert('Please upload category icon', false)
        if (!modalData?.alias?.length) return swalAlert('Please enter atleast one alias', false)
        if (!modalData?.amenities?.length) return swalAlert('Please enter atleast one amenities', false)
        if (!modalData?.actions?.length) return swalAlert('Please enter atleast one action', false)
        if (modalData?.filters?.length) {
            const filterErrors = modalData?.filters?.map((filter, i) => {
                if (!filter.name) return `name is required for filter ${i + 1}`
                if (filter.type === 'dropdown') {
                    if (!filter.options?.length) return `options are required for dropdown ${filter.name}`
                    if (filter.options.filter(option => !option).length) return `empty options are not allowed for dropdown ${filter.name}`
                }
                return false
            }).filter(Boolean)
            if (filterErrors?.length) return swalAlert(filterErrors[0], false)
        }
        if (type?.toLowerCase() == 'add') {
            const data = await Api.createCategory(modalData)
            if (data?.status) {
                swalAlert('Category added sucessfully!!')
                setModalData({})
                fetchAllCategories()
                setIsModalVisible(false)
                setKey(key + 1)
            } else {
                swalAlert(data?.message, false)
            }
            return
        }
        const data = await Api.updateCategory({
            ...modalData
        })
        if (!data?.status) {
            swalAlert(data?.message, false)
            return
        }
        updateCall(modalData?.id, modalData)
        setIsModalVisible(false)
        setType('Add')
    }

    const handleCancel = () => {
        setModalData({})
        setIsModalVisible(false)
        setKey(key + 1)
    }

    const uploadImage = async (e) => {
        setLocalLoader(true)
        const formData = new FormData()
        formData.append('file', e.target.files?.[0])
        formData.append('folder', 'categoryAssets')
        const data = await Api.uploadFile(formData)
        if (!data?.status) {
            return swalAlert(data?.message, false)
        }
        setModalData({ ...modalData, icon: data.data })
        setLocalLoader(false)
    }

    const handleChange = (value) => {
        setModalData({ ...modalData, alias: value })
        refInput?.current['alias']?.blur()
    }
    const handleChangeAmenities = (value) => {
        setModalData({ ...modalData, amenities: value })
        refInput?.current['anemities']?.blur()
    }
    const onChangeCheckBox = (isTrue, value) => {
        let data = []
        if (isTrue) {
            data = [...modalData.actions || [], value]
        }
        else {

            data = modalData?.actions?.filter((val) => val != value)
        }
        setModalData({ ...modalData, actions: data })
        refInput?.current['actions']?.blur()
    }
    const onClickRow = (value) => {
        setModalData(value)
        setIsModalVisible(true)
        setType('Edit')
    }
    return (<>
        <h2>Categories</h2>
        <br />
        <Button type='primary' onClick={() => {
            setIsModalVisible(true)
            setModalData({})
        }} className="my-2">Add Category</Button>
        <Table onRow={(record) => {
            return {
                onClick: (e) => { e?.stopPropagation(); onClickRow(record) }, // click row
            };
        }} columns={columns} dataSource={categories} rowKey='id' />
        <Modal title={`${type} Category`}
            open={isModalVisible} onOk={OnModalOk} onCancel={handleCancel} key={key}>
            <Form.Item label="Name">
                <Input placeholder='Enter Name' maxLength={50}
                    value={modalData?.name} onChange={e => setModalData({ ...modalData, name: e.target.value })} />
            </Form.Item>
            <Form.Item label="Alias">
                <Select
                    ref={ref => refInput.current.alias = ref}
                    mode="tags"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    onChange={handleChange}
                    options={[]}
                    checked={false}
                    value={modalData?.alias}

                />
            </Form.Item>
            <Form.Item label="Amenities">
                <Select
                    ref={ref => refInput.current.anemities = ref}
                    mode="tags"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    onChange={handleChangeAmenities}
                    options={[]}
                    checked={false}
                    value={modalData?.amenities}

                />
            </Form.Item>
            <Form.Item label="Actions">
                <div className='d-flex'>
                    <Checkbox checked={modalData?.actions?.includes('RENT')} onChange={(e) => onChangeCheckBox(e?.target?.checked, 'RENT')}>RENT</Checkbox>
                    <Checkbox checked={modalData?.actions?.includes('BUY')} onChange={(e) => onChangeCheckBox(e?.target?.checked, 'BUY')}>BUY</Checkbox>
                </div>
            </Form.Item>
            <Form.Item label="Icon">
                <Input placeholder='upload icon' type="file" accept="image/*" key={isModalVisible} onChange={uploadImage} />
                {!localLoader ? modalData?.icon && <img src={modalData?.icon} style={{ width: 100, height: 100 }} alt="banner" className='img-fluid' /> :
                    <div className='d-flex justify-content-center align-items-center' style={{ width: 100, height: 100, marginTop: 10 }}>
                        <Spin />
                    </div>
                }
            </Form.Item>
            <label>Filters:</label>
            <DynamicFilters filters={modalData?.filters || []} setFilters={(d) => setModalData({ ...modalData, filters: d })} />
        </Modal>
    </>)
}