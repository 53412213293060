import React, { useState, useEffect, useContext } from "react"
import { Table, Drawer, Descriptions, Button } from "antd"
import Api from "../common/Api"
import { swalAlert } from "../common/utils"
import { CSVLink } from "react-csv"
import { context } from "../context"

export default function Investments() {
    const [selectedInvestment, setSelectedInvestment] = useState(null)
    const [investments, setInvestments] = useState([])
    const { setLoader } = useContext(context)

    useEffect(() => {
        (async () => {
            setLoader(true)
            const res = await Api.getAllInvestments()
            console.log(res)
            setLoader(false)
            if (!res?.status) {
                return swalAlert("failed to get investments", false)
            }
            setInvestments(res.data?.map((val, i) => ({ ...val, sNo: i + 1 })) || [])
        })()
    }, [])

    function RenderPropertyTypes(type) {
        if (type?.toLowerCase() == "agriland") {
            return "agriculture-land"
        }
        return type

    }

    const columns = [{
        title: 'S.No',
        dataIndex: 'sNo',
    }, {
        title: 'Name',
        dataIndex: 'name',
    }, {
        title: 'Property Type',
        dataIndex: 'propertyType',
        render: (r) => RenderPropertyTypes(r)
    }, {
        title: 'Mobile',
        dataIndex: 'mobile',
    }]
    return (
        <>
            <h2>Investments</h2>
            <br />
            <Button type="primary" className="my-2">
                <CSVLink data={investments} filename="investments.csv">
                    Download CSV
                </CSVLink>
            </Button>
            <Table
                onRow={val => ({ onClick: () => setSelectedInvestment(val) })}
                columns={columns}
                dataSource={investments} />
            <Drawer open={selectedInvestment ? true : false} onClose={() => setSelectedInvestment(null)}
                title="Investment Details" width={720}>
                <Descriptions title="Investment Info" bordered>
                    <Descriptions.Item label="Name" span={3}>{selectedInvestment?.name}</Descriptions.Item>
                    <Descriptions.Item label="Mobile" span={3}>{selectedInvestment?.mobile}</Descriptions.Item>
                    <Descriptions.Item label="Email" span={3}>{selectedInvestment?.email}</Descriptions.Item>
                    <Descriptions.Item label="Budget Range" span={3}>{selectedInvestment?.budget}</Descriptions.Item>
                    <Descriptions.Item label="Property Type" span={3}>{selectedInvestment?.propertyType?.toLowerCase() == "buy" ? 'sale' : selectedInvestment?.propertyType}</Descriptions.Item>
                    <Descriptions.Item label="Locations" span={3}>{selectedInvestment?.locations?.join(", ")}</Descriptions.Item>
                    <Descriptions.Item label="Description" span={3}>{selectedInvestment?.desc}</Descriptions.Item>
                    <Descriptions.Item label="Date" span={3}>
                        {`${new Date(selectedInvestment?.createdAt)?.toLocaleDateString()} ${new Date(selectedInvestment?.createdAt)?.toLocaleTimeString()}`}
                    </Descriptions.Item>
                </Descriptions>
                {/* <pre>{JSON.stringify(selectedInvestment, null, 4)}</pre> */}
            </Drawer>
        </>
    )
}