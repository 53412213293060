import React, { useContext } from 'react'
import { Provider, context } from './context'
import { BrowserRouter as Bro, Routes, Route } from "react-router-dom"
import Navbar from './components/Navbar'
import Home from './components/Home'
import Analytics from './components/Analytics'
import Login from './components/Login'
import Loader from './components/Loader'
import Properties from './components/Properties'
import { Breadcrumb, Layout } from 'antd'
import { useEffect } from 'react'
import Users from "./components/Users"
import Admins from './components/Admins'
import Blogs from './components/Blogs'
import Blog from './components/Blog'
import Locations from './components/Locations'
import Categories from './components/Categories'
import './App.css'
import Investments from './components/Investments'
import Ads from './components/Ads'
import Seo from './components/Seo'
import Searchseo from './components/searchseo'
import Categoriesseo from './components/categoriesseo'

const { Content } = Layout

const App = () => {
    const { loader, isLoggedIn, setIsLoggedIn } = useContext(context)
    useEffect(() => {
        const auth = localStorage.getItem('token')
        if (auth) {
            setIsLoggedIn(true)
        }
    }, [])
    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
        >
            {!isLoggedIn ? <Login /> :
                <>
                    <Navbar />
                    <Layout className="site-layout">
                        <Content
                            style={{
                                margin: '0 16px',
                            }}
                        >
                            <Breadcrumb >
                                {/* {breadCrumbs.map((item, index) => (<Breadcrumb.Item key={index}>
									<Link to={'/'}>{item}</Link>
								</Breadcrumb.Item>))} */}
                            </Breadcrumb>
                            <Routes>
                                <Route path='/' element={<Analytics />} />
                                <Route path='/home' element={<Home />} />
                                <Route path='/properties' element={<Properties />} />
                                <Route path="/users" element={<Users />} />
                                <Route path='/employees' element={<Admins />} />
                                <Route path='/blogs' element={<Blogs />} />
                                <Route path='/blogs/add' element={<Blog isEdit={false} />} />
                                <Route path='/blogs/:id' element={<Blog isEdit={true} />} />
                                <Route path='/categories' element={<Categories />} />
                                <Route path='/locations' element={<Locations />} />
                                <Route path='/investments' element={<Investments />} />
                                <Route path='/ads' element={<Ads />} />
                                <Route path='/seo' element={<Seo />} />
                                <Route path='/searchseo' element={<Searchseo />} />
                                <Route path='/categoriesseo' element={<Categoriesseo />} />
                                <Route path='*' element={<h1>404</h1>} />
                            </Routes>
                        </Content>

                    </Layout>
                </>}
            {loader && <Loader />}
        </Layout >
    )
}

const AppComponent = () => (
    <Provider>
        <Bro>
            <App />
        </Bro>
    </Provider>
)

export default AppComponent