import {
    HomeOutlined,
    LogoutOutlined,
    SmileOutlined,
    UngroupOutlined,
    UserOutlined,
    PicRightOutlined,
    AppstoreOutlined,
    BarsOutlined,
    MoneyCollectOutlined
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { confirmSWAL } from '../common/utils'
// import { EMPLOYEE_ROLES } from '../common/enums'

const { Sider } = Layout

const routes = [{
    icon: HomeOutlined,
    path: '',
    title: 'Analytics',
}, {
    icon: UngroupOutlined,
    path: 'properties',
    title: 'Properties',
}, {
    icon: PicRightOutlined,
    path: 'blogs',
    title: 'Blogs',
}, {
    icon: SmileOutlined,
    path: 'employees',
    title: 'Employees',
}, {
    icon: UserOutlined,
    path: 'users',
    title: 'Users',
}, {
    icon: BarsOutlined,
    path: 'locations',
    title: 'Locations',
}, {
    icon: MoneyCollectOutlined,
    path: 'investments',
    title: 'Investments',
}, {
    icon: AppstoreOutlined,
    path: 'seo',
    title: 'Seo',
}, {
    icon: UngroupOutlined,
    path: 'home',
    title: 'Home Page',

},
{
    icon: UngroupOutlined,
    path: 'searchseo',
    title: 'Search Page Seo',
},
{
    icon: AppstoreOutlined,
    path: 'categoriesseo',
    title: 'Categories Seo',
}
]

export default function Navbar() {
    const [collapsed, setCollapsed] = useState(false)
    const location = useLocation()

    return <>
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <div className="logo" />
            <Menu theme="dark" mode="inline"
                selectedKeys={[location.pathname.split('/').filter(x => x.length)?.[0] || '/']}>
                {routes.map(({ icon: Icon, path, title }) => (
                    <Menu.Item key={`${path || '/'}`}>
                        <Icon />
                        <span>{title}</span>
                        <Link to={`/${path}`} />
                    </Menu.Item>
                ))}
                <Menu.Item key={'logout'} onClick={async () => {
                    if (await confirmSWAL('Are you sure?', "Do you want to logout?")) {
                        localStorage.clear()
                        window.location.href = '/'
                    }
                }}>
                    <LogoutOutlined />
                    <span>Logout</span>
                </Menu.Item>
            </Menu>
        </Sider>
    </>
}