import { Input, Button } from 'antd'
import MDEditor, { commands } from '@uiw/react-md-editor'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { context } from '../context'
import { swalAlert } from '../common/utils'
import Api from '../common/Api'
import { useNavigate, useParams } from 'react-router-dom'
const { TextArea } = Input

export default function Blog(props) {
    const { isEdit } = props
    const { setLoader } = useContext(context)
    const [blog, setBlog] = useState({})
    const { id } = useParams()
    const navigate = useNavigate()



    useEffect(() => {
        if (!isEdit) {
            return
        }
        (async () => {
            setLoader(true)
            const response = await Api.searchBlogs({
                query: { _id: id }
            })
            setLoader(false)
            if (!response?.status) {
                swalAlert(response?.message, false)
            }
            const b = response.data?.[0] || {}
            if (b?.ads) {
                b.ads = b.ads.join(",")
            }
            if (b?.seo) {
                b.seo = JSON.stringify(b.seo, null, 2)
            }
            setBlog(b)
        })()
    }, [])

    const uploadImage = async (e, blogImage) => {
        const formData = new FormData()

        formData.append('file', e.target.files[0])
        formData.append('folder', 'blogAssets')
        setLoader(true)
        const response = await Api.uploadFile(formData)
        setLoader(false)
        if (blogImage) {
            return response?.data
        }
        if (!response?.status) {
            swalAlert(response?.message, false)
        }
        setBlog({ ...blog, image: response.data })
    }

    const submitBlog = async () => {
        let err
        if (!blog?.title) {
            err = 'Please enter title'
        } else if (!blog?.shortDescription) {
            err = 'Please enter short description'
        } else if (blog.shortDescription.length < 100 || blog.shortDescription.length > 200) {
            err = 'Short description should be between 100 to 200 characters'
        } else if (!blog?.body) {
            err = 'Please enter body'
        } else if (!blog?.image) {
            err = 'Please upload image'
        } else if (!blog?.category) {
            err = 'Please enter category'
        }

        if (err) {
            return swalAlert(err, false)
        }
        const payload = { ...blog, category: String(blog.category).toLowerCase() }
        if (blog?.seo) {
            payload['seo'] = JSON.parse(blog.seo)
        }
        if (payload?.ads) {
            payload.ads = payload.ads.split(",").map(a => a.trim())
        }
        setLoader(true)
        const response = await (isEdit ? Api.updateBlog(payload) : Api.createBlog(payload))
        setLoader(false)
        if (!response?.status) {
            return swalAlert(response?.message, false)
        }
        swalAlert('success', true)
        if (!isEdit) {
            navigate(`/blogs/${response.data._id}`)
        }
    }
    const handleImageUpload = async (files) => {

        if (files) {
            let file = files.target.files[0];
            const reader = new FileReader()
            reader.onload = (e) => {
                const img = new Image();
                img.onload = async () => {
                    const width = img.width;
                    const height = img.height;
                    const imageUrl = await uploadImage(files, true)
                    insertImage(imageUrl, width, height)
                };
                img.src = e.target.result;
            }
            reader.readAsDataURL(file);
        }

    }


    const insertImage = (imageUrl, width, height) => {
        let markdown = blog?.body
        const newMarkdown = `${markdown} <img style="height:${height}px;width:${width}px" src=${imageUrl} />`;
        setBlog({ ...blog, body: newMarkdown })
    };
    const IconImage = () => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="image">
            <path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a2.81,2.81,0,0,0,.49-.05l.3-.07.07,0h0l.05,0,.37-.14.13-.07c.1-.06.21-.11.31-.18a3.79,3.79,0,0,0,.38-.32l.07-.09a2.69,2.69,0,0,0,.27-.32l.09-.13a2.31,2.31,0,0,0,.18-.35,1,1,0,0,0,.07-.15c.05-.12.08-.25.12-.38l0-.15A2.6,2.6,0,0,0,22,19V5A3,3,0,0,0,19,2ZM5,20a1,1,0,0,1-1-1V14.69l3.29-3.3h0a1,1,0,0,1,1.42,0L17.31,20Zm15-1a1,1,0,0,1-.07.36,1,1,0,0,1-.08.14.94.94,0,0,1-.09.12l-5.35-5.35.88-.88a1,1,0,0,1,1.42,0h0L20,16.69Zm0-5.14L18.12,12a3.08,3.08,0,0,0-4.24,0l-.88.88L10.12,10a3.08,3.08,0,0,0-4.24,0L4,11.86V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"></path>
        </svg>
    );
    console.log("ssss", blog)
    return (
        <>

            <h2>Blog</h2>
            <br />
            <div className='overflow-hidden'>
                <div className=' row '>
                    <div className='col-12 col-md-10'>
                        Title:
                        <Input value={blog?.title} onChange={(e) => setBlog({ ...blog, title: e.target.value })}
                            className='py-4' placeholder="Enter your title here ....." />
                    </div>
                </div>
                <br />
                Custom URL:
                <Input value={blog?.url} onChange={(e) => setBlog({ ...blog, url: e.target.value })}
                    className='py-4 my-3' placeholder="custom url" />
                <br />
                Category:
                <Input value={blog?.category} onChange={(e) => setBlog({ ...blog, category: e.target.value })}
                    className='py-4 my-3' placeholder="Category" />

                <br />
                Short Description: {`(${blog?.shortDescription?.length || 0}/200)`}
                <Input value={blog?.shortDescription} onChange={(e) => setBlog({ ...blog, shortDescription: e.target.value })}
                    className='py-4 my-3' placeholder="short description" maxLength={200} />
                <div className='my-3 p-3 border bordre-dark-50 rounded bg-white'>
                    Banner:
                    <input placeholder='upload banner' type="file" accept="image/png, image/jpeg" onChange={uploadImage} />
                    {blog?.image && <img src={blog?.image} alt="banner" className='img-fluid' style={{
                        maxHeight: '200px',
                    }} />}
                </div>
                <div className='my-3'>
                    <MDEditor
                        height={"70vh"}

                        value={blog?.body}
                        onChange={(e) => setBlog({ ...blog, body: e })}
                        commands={[
                            ...commands.getCommands().map((r) => {
                                if (r["keyCommand"] == "image") {
                                    return
                                }
                                if (r["keyCommand"] === "group") {
                                    return r["children"].map(cr => r);
                                }
                                return r;
                            }).flat().map(r => r),

                            {
                                name: 'upload-image',
                                keyCommand: 'upload-image',
                                buttonProps: { 'aria-label': 'Upload Image' },
                                icon: <img src="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png" style={{ width: '15px', height: '15px' }} />,
                                execute: () => { document.getElementById('imageFileInput').click(); }
                            }
                        ]}
                    />
                    <input
                        id="imageFileInput"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => { if (e) { handleImageUpload(e) } }}
                    />
                </div>
                <div className='my-3'>
                    SEO:
                    <TextArea
                        style={{ width: "100%" }}
                        rows={10}
                        placeholder="enter seo json here"
                        value={blog?.seo}
                        onChange={e => setBlog({ ...blog, seo: e.target.value })} />
                </div >
                <Input value={blog?.ads} placeholder="Enter comma separated ad ids"
                    onChange={(e) => setBlog({ ...blog, ads: e.target.value })} />
                <Button type="primary" className='my-2' onClick={submitBlog}>{isEdit ? "Update" : "Submit"}</Button>
            </div>
        </>
    )
}