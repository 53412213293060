import config from "../config"
import axios from 'axios'
import { swalAlert } from "./utils"

let axiosInstance = axios.create({
    baseURL: config.BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        "accept": 'application/json'
    },
})

axiosInstance.interceptors.request.use(function (config) {
    try {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers.Authorization = token
        }
        return config
    } catch {
        return config
    }
})

const Api = {
    sendOtp: payload => callAPI(`/employee/verify`, 'POST', payload),
    login: payload => callAPI(`/employee/login`, 'POST', payload),
    createEmployee: payload => callAPI(`/employee`, 'POST', payload),
    getAllEmployees: payload => callAPI(`/employee`, 'GET', payload),
    updateEmployee: payload => callAPI(`/employee`, 'PUT', payload),
    deleteEmployee: payload => callAPI(`/employee`, 'DELETE', payload),
    uploadFile: payload => callAPI(`/employee/upload`, 'POST', payload),
    generateUserLoginOtp: payload => callAPI(`/employee/userAccess`, 'POST', payload),

    searchUsers: payload => callAPI(`/user/search`, 'POST', payload),
    updateUser: payload => callAPI(`/user`, 'PUT', payload),
    deleteUser: id => callAPI(`/user/${id}`, 'DELETE'),
    createUser: payload => callAPI(`/user/add`, 'POST', payload),
    addLead: payload => callAPI(`/user/leads`, 'POST', payload),

    searchProperties: payload => callAPI(`/property/search`, 'POST', payload),
    editProperty: payload => callAPI(`/property`, 'PUT', payload),
    deleteProperty: id => callAPI(`/property/${id}`, 'DELETE'),

    getAnalytics: () => callAPI(`/home/analytics`),

    getBlogs: payload => callAPI(`/blog`, 'GET', payload),
    createBlog: payload => callAPI(`/blog`, 'POST', payload),
    updateBlog: payload => callAPI(`/blog`, 'PUT', payload),
    searchBlogs: payload => callAPI(`/blog/search`, 'POST', payload),
    deleteBlog: id => callAPI(`/blog/${id}`, 'DELETE'),

    getAllCategories: payload => callAPI(`/category`, 'GET', payload),
    searchCategories: payload => callAPI(`/category/search`, 'POST', payload),
    createCategory: payload => callAPI(`/category`, 'POST', payload),
    updateCategory: payload => callAPI(`/category`, 'PUT', payload),
    deleteCategory: id => callAPI(`/category/${id}`, 'DELETE'),

    addAreaData: payload => callAPI(`/locations`, 'POST', payload),
    getAreaData: payload => callAPI(`/locations`, 'GET', payload),
    editAreaData: payload => callAPI(`/locations`, 'PUT', payload),

    getAllInvestments: () => callAPI(`/investments`),

    getMetaTags: payload => callAPI(`/seo/search`, 'POST', payload),
    updateMetaTags: payload => callAPI(`/seo`, 'POST', payload),
    deleteMetaTags: payload => callAPI(`/seo`, 'DELETE', payload),

    getHomeAreaData: payload => callAPI(`/areas/search`, 'POST', payload),
    updateHomeAreaData: payload => callAPI(`/areas`, 'POST', payload),
    deleteArea: id => callAPI(`/areas/${id}`, 'DELETE'),

    categorySeo: payload => callAPI(`/categoryseo`, 'POST', payload),
    getCategorySeo: payload => callAPI(`/categoryseo/admin`, 'GET', payload),
    updateCategorySeo: payload => callAPI(`/categoryseo`, 'PUT', payload),
    deleteCategorySeo: payload => callAPI(`/categoryseo`, 'DELETE', payload),
}

export default Api

async function callAPI(url, method = 'GET', data = {}, headers = {}) {
    try {
        console.log(`API: ${method} ${url} ${JSON.stringify(data)} ${headers}`)
        const response = await axiosInstance({
            method,
            url,
            data,
            params: method === 'GET' ? data : {},
            headers
        })
        return response.data
    } catch (err) {
        //console.log('API ERROR', err)
        if (err?.response?.status === 401) {
            swalAlert('Session Expired', false)
            localStorage.clear()
            window.location.href = '/'
        }
        return err?.response?.data
    }
}