import React, { useContext, useEffect, useState } from 'react'
import { context } from '../context'
import Api from '../common/Api'
import { swalAlert } from '../common/utils'
import { Card, Col, Row, Statistic } from 'antd'
import { colorsArray } from '../common/colors'
import { useNavigate } from "react-router-dom"
import 'chart.js/auto'
import { Pie, Line } from 'react-chartjs-2'


const mainCards = ['users', 'properties', 'employees', 'blogs']
export default function Home() {
    const { setLoader } = useContext(context)
    const navigate = useNavigate()
    const [analytics, setAnalytics] = useState({})
    useEffect(() => {
        (async () => {
            setLoader(true)
            const response = await Api.getAnalytics()
            if (!response || !response?.status) {
                setLoader(false)
                swalAlert(response?.message, false)
            }
            setAnalytics(response?.data)
            setLoader(false)
        })()
    }, [])

    return <>
        <h2>Analytics</h2>
        <br />
        <Row gutter={16} >
            {mainCards?.map((anal, i) => <>
                <Col span={6} key={i}>
                    <Card bordered={true} style={{ backgroundColor: colorsArray[i] }} onClick={() => {
                        navigate(`/${anal}`)
                    }}>
                        <Statistic
                            title={anal}
                            value={analytics && analytics[anal] || 0}
                            valueStyle={{ color: '#fff' }}
                        />
                    </Card>
                </Col>
            </>)}
        </Row>

        {analytics && <Row gutter={30} className="my-5">
            <Col span={8} style={{ maxHeight: 400 }}>
                <Line data={{
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                    datasets: [
                        {
                            label: 'Users',
                            data: [0, 10, 5, 2, 20, 30, 45],
                            borderColor: 'rgb(255, 99, 132)',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        }
                    ],
                }} options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: 'User Growth',
                        },
                    },
                }} />
            </Col>
            <Col span={8} style={{ maxHeight: 400 }}>
                <Pie data={{
                    labels: analytics?.userRoles?.map((role) => role.role) || [],
                    datasets: [
                        {
                            label: 'Count',
                            data: analytics?.userRoles?.map((role) => role.count) || [],
                            backgroundColor: colorsArray,
                        },
                    ],
                }} />
            </Col>
            <Col span={8} style={{ maxHeight: 400 }}>
                <Pie data={{
                    labels: analytics?.propertyTypes?.map((role) => role.type) || [],
                    datasets: [
                        {
                            label: 'Count',
                            data: analytics?.propertyTypes?.map((role) => role.count) || [],
                            backgroundColor: colorsArray,
                        },
                    ],
                }} />
            </Col>
        </Row>}

        {/* <pre>{JSON.stringify(analytics, null, 4)}</pre> */}
    </>
}