import { Select, Input, Button } from "antd"
import React, { useState, useEffect, useContext } from "react"
import Api from "../common/Api"
import { swalAlert } from "../common/utils"
import { context } from "../context"
import _ from "lodash"
const { TextArea } = Input
const options = ["home", "property", "agents", "builder", "blog"].map(p => ({ value: p, label: p }))

export default function Seo() {
    const [page, setPage] = useState("home")
    const { setLoader } = useContext(context)
    const [data, setData] = useState(null)
    const [remarks, setRemarks] = useState('')
    const [schema, setSchema] = useState('')

    const getMetaTags = async (page) => {
        setLoader(true)
        const r = await Api.getMetaTags({ page })
        setLoader(false)
        if (!r?.status) {
            return swalAlert(r?.message, false)
        }
        const result = _.omit((r?.data?.[0] || {}), ["_id", "page", "createdAt", "updatedAt", "remarks", "schema"])
        setRemarks(r?.data[0]?.remarks)
        setSchema(r?.data[0]?.schema)
        setData(JSON.stringify(result, null, 2))
    }

    const updateTag = async () => {
        let payload = {}
        try {
            payload = JSON.parse(data)
        } catch (error) {
            alert(data)
            return swalAlert("Invalid JSON", false)
        }
        setLoader(true)
        const r = await Api.updateMetaTags({ ...payload, page, remarks, schema })
        setLoader(false)
        if (!r?.status) {
            return swalAlert(r?.message, false)
        }
        swalAlert(r?.message)
    }

    useEffect(() => {
        getMetaTags(page)
    }, [page])

    return (
        <>
            <h2>Seo</h2>
            <br />
            <Select
                value={page}
                onChange={val => setPage(val)}
                style={{ width: 120 }}
                options={options}
                className="my-3" />
            <br />
            <TextArea
                style={{ width: "100%" }}
                rows={10}
                placeholder="enter seo json here"
                value={data}
                onChange={e => setData(e.target.value)} />
            <br />
            <h5 className="my-3">Schema</h5>
            <TextArea
                style={{ width: "100%" }}
                rows={10}
                placeholder="Enter schema here"
                value={schema}
                onChange={e => setSchema(e.target.value)} />
            <br />
            <h5 className="my-3">Remarks </h5>
            <TextArea
                style={{ width: "100%" }}
                rows={3}
                placeholder="Enter remarks here"
                value={remarks}
                onChange={e => setRemarks(e.target.value)} />
            <br />
            <Button type="primary" className="my-3" onClick={updateTag}>Save</Button>
        </>
    )
}