import React, { useState, useEffect, useContext } from "react"
import { Button, Select, Input, Table, Modal } from "antd"
import { context } from "../context"
import Api from "../common/Api"
import { confirmSWAL, swalAlert, makeAutoComplete } from "../common/utils"

const options = ["popular", "affordable"].map((item, i) => ({ label: item, value: item, key: i }))

export default function Home() {
    const [category, setCategory] = useState("popular")
    const [data, setData] = useState([])
    const { setLoader } = useContext(context)
    const [currentArea, setCurrentArea] = useState({})

    const getAreas = async (query) => {
        setLoader(true)
        const r = await Api.getHomeAreaData(query)
        setLoader(false)
        if (!r?.status) {
            return swalAlert(r?.message, false)
        }
        setData(r.data)
    }

    const saveArea = async () => {
        if (!currentArea.area) {
            return swalAlert("Please add area", false)
        }
        if (!currentArea.sqft) {
            return swalAlert("Please enter sqft", false)
        }
        if (!currentArea._id && data.filter(item => item.area === currentArea.area).length > 0) {
            return swalAlert(`${currentArea.area} Area already exists`, false)
        }
        setLoader(true)
        await Api.updateHomeAreaData(currentArea)
        await getAreas({ category })
        setLoader(false)
        swalAlert("updated successfully")
        setCurrentArea({})
    }

    useEffect(() => {
        getAreas({ category })
    }, [category])

    useEffect(() => {
        if (!document.getElementById(`area-autocomplete`)) return
        document.getElementById(`area-autocomplete`).value = currentArea.area || ""
        try {
            makeAutoComplete(`area-autocomplete`, a => {
                setCurrentArea({ ...currentArea, area: a })
            })
        } catch {
            console.log("error in autocomplete")
        }
    }, [Object.keys(currentArea).length > 0])

    const columns = [{
        title: 'Id',
        dataIndex: '_id',
        key: '_id',
    }, {
        title: 'Area',
        dataIndex: 'area',
        key: 'area'
    }, {
        title: 'Sqft',
        dataIndex: 'sqft',
        key: 'sqft',
    }, {
        title: 'Action',
        key: 'action',
        render: (text, record, i) => {
            return <>
                <Button onClick={() => setCurrentArea(record)} className="mx-2" type="primary">Edit</Button>
                <Button danger onClick={async () => {
                    if (!(await confirmSWAL("Are you sure?"))) return
                    setLoader(true)
                    const resp = await Api.deleteArea(record._id)
                    setLoader(false)
                    if (!resp?.status) return swalAlert(resp?.message, false)
                    const d = [...data]
                    d.splice(i, 1)
                    setData(d)
                    swalAlert("deleted successfully")
                }} className="mx-2">Delete</Button>
            </>
        }
    }]
    return (
        <>
            <h1>Home</h1>
            <br />
            All Areas: <Select
                style={{ width: 120 }}
                options={options}
                value={category}
                onChange={val => setCategory(val)} />
            <br />
            <Button onClick={() => setCurrentArea({
                category
            })} className="my-2" type="primary">Add Area</Button>
            <Table dataSource={data} columns={columns} />
            <Modal
                okText="Save"
                title="Add Area"
                open={Object.keys(currentArea).length > 0}
                onOk={saveArea}
                onCancel={() => setCurrentArea({})}
            >
                Area: <input
                    className="my-2"
                    id={`area-autocomplete`}
                    placeholder="Select area"
                    style={{ width: 200 }} />
                <br />
                Sqft: <Input value={currentArea.sqft} onChange={(e) => {
                    if (isNaN(e.target.value)) return
                    setCurrentArea({ ...currentArea, sqft: e.target.value })
                }} style={{ width: 200 }} className="my-2" />
                <br />
            </Modal>
        </>
    )
}