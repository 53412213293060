
import React, { useState, useEffect, useContext, useRef } from 'react'
import Api from '../common/Api'
import { context } from '../context'
import { Button, Input, Table, Form, Switch, Modal, Select, Statistic, Row, Col, Card, Dropdown, Drawer, Space } from 'antd'
import { confirmSWAL, getPropertyUrl, getSearchUrl, makeAutoComplete, swalAlert } from '../common/utils'
import { EMPLOYEE_ROLES, PROPERTY_TYPES } from '../common/enums'
import { colorsArray } from '../common/colors'
import _ from "lodash"
import { SearchOutlined } from '@ant-design/icons'
import config from '../config'
const { TextArea } = Input

export default function Searchseo() {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalData, setModalData] = useState({})
    const [area, setarea] = useState(null)
    const inputref = useRef(null)
    const [data, setData] = useState([])
    const [selectedProperty, setSelectedProperty] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const searchInput = useRef(null)
    const [searchedColumn, setSearchedColumn] = useState('')
    const [remarks, setRemarks] = useState('')

    async function getMetaTags() {
        let result = await Api.getMetaTags({ seopage: "search" })
        if (result?.status) {
            setData(result?.data)
        }
    }
    useEffect(() => {
        getMetaTags()
    }, [])

    const handleCancel = () => {
        setModalData({})
        setarea(null)
        setIsModalVisible(false)
        if (inputref?.current) {
            inputref.current.value = ''
        }
    }
    useEffect(() => {
        makeAutoComplete("area-autocomplete", a => setarea(a))
    }, [isModalVisible])

    const onOk = async () => {
        if (!area || !modalData?.category || !modalData?.type) {
            return swalAlert("please select all feilds", false)
        }
        let payload = {
            ...modalData,
            seopage: "search",
            page: (area + modalData?.category + modalData?.type)?.toLowerCase(),
            area: area
        }
        const r = await Api.updateMetaTags(payload)
        if (r?.status) {
            getMetaTags()
            handleCancel()
        }
    }
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchedColumn(dataIndex)
    }

    const rendetText = (text) => {
        if (text?.toLowerCase() == "buy") {
            return "sale"
        }
        else if (text?.toLowerCase() == "agriland") {
            return "agriculture-land"
        }
        return text
    }
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && clearFilters()}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString()?.toLowerCase()?.includes(value?.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <div className=''>{text ? text?.toString() : ''}</div>
            ) : (
                rendetText(text)
            ),
    })
    const columns = [
        {
            title: 'Category',
            dataIndex: 'category',
            ...getColumnSearchProps("category")

        }, {
            title: 'Type',
            dataIndex: 'type',
            ...getColumnSearchProps("type")
        },
        {
            title: 'Area',
            dataIndex: 'area',
            ...getColumnSearchProps("area")
        },
    ]

    const updateTag = async () => {
        if (!selectedProperty?.content) {
            return swalAlert("please add content")
        }
        let payload = {
            ...selectedProperty,
            content: JSON.parse(selectedProperty?.content),
            remarks: remarks
        }
        const r = await Api.updateMetaTags(payload)
        if (r?.status) {
            swalAlert(r?.message)
            getMetaTags()
            handleCancel()
            setSelectedProperty(null)
        }
    }
    const onClickViewProperty = () => {
        let data = {
            type: selectedProperty?.category,
            action: selectedProperty?.type,
            area: selectedProperty?.area
        }
        window.open(`${config.MAIN_WEB_URL}/${getSearchUrl(data)}`, '_blank').focus()

    }
    return (
        <div className='p-1'>
            <h2>Search seo</h2>
            <Button type='primary' onClick={() => {
                setIsModalVisible(true)
                setModalData({
                })
            }} className="my-2">Add Seo</Button>
            <Modal title={'Add Seo'}
                open={isModalVisible} onOk={onOk} onCancel={handleCancel}>
                <Form.Item label="Type">
                    <Select
                        placeholder="Enter type"
                        value={modalData?.type}
                        onChange={e => { setModalData({ ...modalData, type: e }) }}
                        options={
                            [{ key: "sale", value: "buy" }, { key: "rent", value: "rent" }].map((val) => ({
                                value: val?.value,
                                label: val?.key
                            }))
                        } />
                </Form.Item>
                <Form.Item label="category">
                    <Select
                        placeholder="Enter category"
                        value={modalData?.category}
                        onChange={e => {
                            setModalData({ ...modalData, category: e })
                        }}
                        options={
                            PROPERTY_TYPES.filter(val => val?.actions.includes(modalData?.type?.toUpperCase())).map((val) => ({
                                value: val.key,
                                label: val.title
                            }))
                        } />
                    <p style={{ color: 'red' }}>(* please select type to get categories list)</p>
                </Form.Item>

                <div className='d-flex align-items-center '>
                    <p className='m-0 pe-4'>area:</p>
                    <input type='text' ref={inputref} id="area-autocomplete" style={{ width: '100%', height: 35, borderColor: 'lightgrey' }} />
                </div>
            </Modal >
            <Table onRow={val => ({
                onClick: () => {
                    let res = _.omit((val || {}), ["_id", "createdAt", "updatedAt", "remarks"])
                    res = {
                        ...res,
                        content: JSON.stringify(res?.content, null, 2)
                    }
                    setSelectedProperty(res)
                    setRemarks(val["remarks"])
                }
            })} columns={columns} dataSource={data} rowKey='id' />
            <Drawer open={selectedProperty ? true : false} onClose={() => setSelectedProperty(null)}
                title={rendetText(selectedProperty?.category) + " for " + rendetText(selectedProperty?.type) + " in " + selectedProperty?.area + " (Seo Tags Details)"} width={720}>
                <Button type="primary" className="my-3 me-3" onClick={() => onClickViewProperty()}>View property</Button>

                <TextArea
                    style={{ width: "100%" }}
                    rows={10}
                    placeholder="enter seo json here"
                    value={selectedProperty?.content}
                    onChange={e => setSelectedProperty({ ...selectedProperty, content: e.target.value })} />
                <br />
                <h5 className="my-3">Remarks </h5>
                <TextArea
                    style={{ width: "100%" }}
                    rows={3}
                    placeholder="Enter remarks here"
                    value={remarks}
                    onChange={e => setRemarks(e.target.value)} />
                <br />
                <Button type="primary" className="my-3 me-3" onClick={updateTag}>Save</Button>
                <Button type="danger" className="my-3" onClick={async () => {
                    if (await confirmSWAL(`Are you sure, do you want to delete ?`)) {

                        let result = await Api.deleteMetaTags({ page: selectedProperty?.page })
                        if (result?.status) {
                            getMetaTags()
                            setSelectedProperty(null)
                        }
                        swalAlert(result?.message || "something went wrong")
                    }
                }}>Delete</Button>
            </Drawer>
        </div >
    )


}