import React, { useState, useEffect, useContext } from 'react'
import Api from '../common/Api'
import { context } from '../context'
import { Select } from 'antd'
import { makeAutoComplete, swalAlert } from '../common/utils'
import { Line } from "react-chartjs-2"

export default function Categories() {
    const { setLoader } = useContext(context)
    const [area, setarea] = useState(null)
    const [priceData, setPriceData] = useState({})
    const [data, setData] = useState([{ price: '', year: '' }])
    const [key, resetKey] = useState(false)
    const [addAreas, setAddAreas] = useState([])
    // const [selectType, setSelectType] = useState(['all'])
    const [addOrEditDetailsDropDown, setAddOrEditDetailsDropDown] = useState([])
    const [edit, setEdit] = useState(false)
    useEffect(() => {
        setAddOrEditDetailsDropDown([])
        makeAutoComplete("area-autocomplete", a => setarea(a))
    }, [])

    const fetchPriceData = async (area) => {
        setLoader(true)
        let result = await Api.getAreaData({ area: area })
        setLoader(false)
        if (!result?.status || !result?.data?.length) {
            setPriceData([])
            setAddAreas(area)
            return
        }
        let { price, years } = result.data[0]
        let data = price.map((val, index) => {
            return {
                x: years[index],
                y: val
            }
        })
        setPriceData({ areas: result.data[0]?.area, data })
    }

    useEffect(() => {
        if (area) {
            fetchPriceData(area)
        }
    }, [area])

    const onClickAddButton = () => {
        setData([...data, {}])

    }
    const onClickDeleteButton = (index) => {
        let result = data?.filter((val, ind) => ind != index)
        setData(result)

    }
    const onChangeValue = (value, index) => {
        var reg = /^\d+$/;
        if (value && !reg.test(value)) {
            return
        }
        let result = [...data]
        result[index]['price'] = value
        setData(result)

    }
    const onChangeYearValue = (value, index) => {
        var reg = /^\d+$/;
        if (value && !reg.test(value)) {
            return
        }
        let result = [...data]
        result[index]['year'] = value
        setData(result)
    }
    const postDetails = async () => {
        if (!addAreas?.length) {
            return swalAlert('please send location', false)
        }
        let localData=[...data]
        localData.sort((a,b)=>a.year-b.year)
        let price = localData?.map(val => val.price)
        let years = localData?.map(val => val.year)
        if (years.includes(null) || years.includes(undefined) || years.includes('') || data.some((val) => val.year < 1948 || val.year > new Date().getFullYear())) {
            return swalAlert(`enter year between 1948 and ${new Date().getFullYear()} `, false)
        }
        if (price.includes(null) || price.includes(undefined) || price.includes('')) {
            return swalAlert('price should be empty', false)
        }
        setLoader(true)
        let result
        if (!edit) {
            result = await Api.addAreaData({ area: addAreas, price: price, years: years })
        }
        else {
            result = await Api.editAreaData({ area: addAreas, price: price, years: years })
        }
        setLoader(false)
        if (result?.status) {
            setData([{}])
            swalAlert(result?.message)
            fetchPriceData(area)
            resetKey(!key)
        }
        else {
            swalAlert(result?.message)
        }
        setEdit(false)

    }
    const onSelectMultipleAddress = (value) => {
        setAddAreas(value)
    }
    /*const onChangeSelectType = async (value) => {
        setSelectType(value)
        if (value == "all") {
            return
        }
        setLoader(true)
        let result = await Api.getAreaData()
        setLoader(false)
        let totalAreas = []
        if (result?.status) {
            for (let i = 0; i < result.data.length; i++) {
                totalAreas.push(...result.data[i].area)
            }
        }
        let filterAreas = [].filter(value => !totalAreas.includes(value))
        if (value == 'data') {
            setAddOrEditDetailsDropDown(filterAreas)
            return
        }
        setAddOrEditDetailsDropDown([])
    }*/
    const onClickEditDetails = () => {
        setAddAreas(priceData?.areas)
        let result = priceData?.data?.map(val => {
            return {
                price: val.y,
                year: val.x
            }
        })
        setData(result)
        setEdit(true)
    }

    return (
        <div className='row'>
            <div className='col-6'>
                <h2>Locations</h2>
                <br />
                Select area: <input type='text' id="area-autocomplete" style={{ width: 200 }} />
                {/* <Select showSearch value={area} onChange={setarea}
                    placeholder="Select areas" options={dropDownAreas.map(a => ({ label: a, value: a }))}
                    filterOption={(input, option) => (option?.label ?? '').toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    } style={{ width: 200 }} /> */}
                {/* <Select value={selectType} onChange={onChangeSelectType} placeholder="Select type"
                    options={[{ value: 'all', label: 'all' }, { value: 'data', label: 'data' }, { value: 'no data', label: 'no data' }]}
                    style={{ width: 200 }} /> */}
                <br />
                {priceData?.data?.length && <button className='btn btn-primary mt-3' onClick={() => onClickEditDetails()}>Edit Details</button>}
                <div className='m-0 mt-3 d-flex'><p className='m-0 py-2 me-2'> areas:</p> {priceData?.areas?.map((val, index) => { return (<p className='m-0 ms-1 border p-2 rounded' key={index}>{val}</p>) })}</div>
                {area && <div className='w-100 my-3 '>
                    <Line data={{
                        labels: priceData?.data?.map(value => value.x),
                        datasets: [{
                            label: 'price trends',
                            data: priceData?.data?.map(value => value.y),
                            backgroundColor: '#FF622A',
                            tension: 0.4,
                            // fill: true
                        }],
                    }} />
                </div>}
            </div>
            {edit || (!priceData?.data?.length && area) ? <div key={key} className='col-6 border-start mb-5'>
                <h3>Add Details <span className='fs-6'> (please enter years between 1948 and {new Date().getFullYear()})</span></h3>
                {/* <Select
    
                    mode="multiple"
                    allowClear
                    placeholder="Please select"
                    onChange={(value) => onSelectMultipleAddress(value)}
                    options={addOrEditDetailsDropDown.map(a => ({ label: a, value: a }))}
                    filterOption={(input, option) => (option?.label ?? '').toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    style={{ width: '80%' }}
                    value={addAreas}
                /> */}
                {data?.map((val, index) => {
                    return (
                        <div key={index} className='row mt-2'>
                            <div className='col-5'>
                                <p>Year </p>
                                <input value={val?.year} onChange={(e) => onChangeYearValue(e.target.value, index)} className='w-100 py-2 rounded' />
                            </div>
                            <div className='col-5'>
                                <p>Price</p>
                                <input value={val?.price} onChange={(e) => onChangeValue(e.target.value, index)} className='w-100 py-2 rounded' />
                            </div>
                            <div className='col-2 d-flex align-items-end'>
                                <button onClick={() => { onClickDeleteButton(index) }} className='btn btn-danger'>Delete </button>
                            </div>
                        </div>
                    )
                })}
                <button onClick={() => onClickAddButton()} className='  btn btn-primary mt-4 py-2 px-5'>Add +</button>
                <button onClick={() => postDetails()} className='btn btn-success mt-4  ms-3 px-4 py-2' >save</button>
            </div> : null}
        </div>
    )
}