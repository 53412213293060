import React, { createContext, useState } from "react"

export const context = createContext()
export const Provider = props => {
    const [loader, setLoader] = useState(false)
    const [breadCrumbs, setBreadCrumbs] = useState(['Home'])
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [user, setUser] = useState({})
    return (
        <context.Provider value={{
            loader, setLoader,
            breadCrumbs, setBreadCrumbs,
            isLoggedIn, setIsLoggedIn,
            user, setUser
        }}>
            {props.children}
        </context.Provider>
    )
}
export const Consumer = context.Consumer