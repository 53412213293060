export const EMPLOYEE_ROLES = {
    ADMIN: 'ADMIN',
    MANAGER: 'MANAGER',
    MARKETING: 'MARKETING',
}

export const USER_ROLES = {
    BUYER: 'BUYER',
    AGENT: 'AGENT',
    BUILDER: 'BUILDER',
}

export const PROPERTY_TYPES = [
    { title: 'Apartment', key: 'apartment', actions: ['BUY'] },
    { title: 'Villas', key: 'villa', actions: ['BUY'] },
    {
        title: 'Independent house',
        key: 'independenthouse',
        actions: ['BUY']
    },
    { title: 'Farmhouse', key: 'farmhouse', actions: ['BUY'] },
    { title: 'Office space', key: 'office', actions: ['RENT', 'BUY'] },
    {
        title: 'Commerical showrooms',
        key: 'commericalshowrooms',
        actions: ['RENT', 'BUY']
    },
    {
        title: 'Commerical shop',
        key: 'commercialshops',
        actions: ['RENT', 'BUY']
    },
    { title: 'Warehouse', key: 'warehouse', actions: ['RENT', 'BUY'] },
    {
        title: 'Industrial shed',
        key: 'industrialshed',
        actions: ['RENT', 'BUY']
    },
    { title: 'Plot', key: 'plot', actions: ['BUY'] },
    { title: 'land', key: 'land', actions: ['BUY'] },
    {
        title: 'Agricultural land',
        key: 'agriland',
        actions: ['RENT', 'BUY']
    },
    { title: 'Farm land', key: 'farmland', actions: ['RENT', 'BUY'] },
    {
        title: 'Industrial land',
        key: 'industrialland',
        actions: ['RENT', 'BUY']
    },
    {
        title: 'Commercial land',
        key: 'commercialland',
        actions: ['RENT', 'BUY']
    },
    {
        title: 'Commercial plot',
        key: 'commercialplot',
        actions: ['RENT', 'BUY']
    }
]